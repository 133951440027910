import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import 'moment/locale/ru';
import axios from '../lib/axios'
import { useAdaptivity, ScreenSpinner, Div, PanelHeader, Group,  Avatar, Placeholder, Button, Banner, Text, CardGrid, Card, Pagination } from '@vkontakte/vkui';
import { Icon56LikeOutline, Icon20FireCircleFillRed, Icon12Circle } from '@vkontakte/icons';
 
const Like = ({ go , setPopout , setActivePanel , setSnackbar , setSnackbarText , setUsersId , setUsersPage , usersPage, setActiveModal }) => {
 
	const { viewWidth } = useAdaptivity(); // Ширина окна
    const isDesktop = viewWidth > '2'; // Размер экрана для ПК версии

	const [currentPage, setCurrentPage] = useState(usersPage); // Текущая страница 
	const [siblingCount, setSiblingCount] = useState(1); // Кол-во всегда видимых страниц по краям текущей страницы 
	const [boundaryCount, setBoundaryCount] = useState(1); // Кол-во всегда видимых страниц в начале и в конце 
	const [totalPages, setTotalPages] = useState(1); // Общее кол-во страниц 
	const [disabled, setDisabled] = useState(false); // Блокировка всех кнопок 
 
	const [users, setUsers] = useState([]); // Список пользователей
	  
	// Pagination Клик
	const handleChange = React.useCallback((page) => {  
		setCurrentPage(page); // Текущая страница 
		fetchDataLike(page); // Axios запрос
	},[]);
	
	// Клик по пользователею
	const goUser = (e) => {  
		setActivePanel('user') , // Имя Страницы
		setUsersPage(currentPage) , // Id Страницы
		setUsersId(e.currentTarget.dataset.id) // Id пользователя 
	}
	
	// Кнопка Vip модальное окно
	const modalVip = () => ( 
		setActiveModal('vip')
	);
 
	const fetchDataLike = async (currentPage) => { // Axios запрос Search
	setPopout(<ScreenSpinner size='large' />); // Включить Spinner
	axios
		.get('/api/like?page='+currentPage)
		.then(response => {
			setUsers(response.data.data); // Пользователи
			setCurrentPage(response.data.current_page); // Текущая страница. 
			setTotalPages(response.data.last_page); // Общее кол-во страниц. 
			setPopout(null); // Spinner   
		})
		.catch(error => {
			if(error.response.status){
				if(error.response.status == 401){
					setSnackbar('error'); // Error	
					setSnackbarText('Пользователь не авторизован'); // Error Text
				}else{
					setSnackbar('error'); // Error	
					setSnackbarText('Ошибка: ' + error.response.status); // Error Text
				} 
			} 
			if(error.code == 'ERR_NETWORK'){
				setSnackbar('error'); // Error	
				setSnackbarText('Доступ в Интернет закрыт'); // Error Text
			}
			if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Ошибка: ' + error.response.data.message);
						} 
					}
			setPopout(null);  // Выключить Spinner 
		});
	}  
 
	useEffect(() => { // UseEffect
  
		fetchDataLike(currentPage); // Axios запрос Search
		setUsersPage(1); // Первая страница search
 
	}, []);
 
 
 
 
	return (
		<>
		{ !isDesktop && ( <PanelHeader>Симпатии</PanelHeader> )}
			<Div style={ isDesktop ? { } : { marginBottom:'20px', paddingLeft: "0px" , paddingRight: "0px" } }>
 
				{!users.length ? (
								
					<Card>
						<Placeholder
							header="Симпатии не найдены"
							icon={<Icon56LikeOutline width={56} height={56} />}
							action={<Button onClick={modalVip} data-story="profil" size="l">Стать популярней </Button>}
						>
							Тут хранятся симпатии за последние 30 дней
						</Placeholder>
					</Card>
 		
				):( 
					<CardGrid size={isDesktop ? ( "s" ) : ( "m" )} style={ isDesktop ? { } : { padding: '0px 6px' }} >
					
						<Card>
							<div className="SearchUserVipCard">
								<Icon56LikeOutline className="puls" width={56} height={56} />
								<center>Просмотр симпатий <br/>только для vip пользователей</center>
								<Button style={{ marginTop:'20px'}} onClick={modalVip} data-story="profil" size="s">Подробней</Button>
							</div>
						</Card>
 
						{users.map(userSearch => ( 
							
							<Card key={userSearch.id} onClick={goUser} data-id={userSearch.user.id} className="SearchUserCard" >
 
								<div className="SearchUserName">
									<div> {userSearch.user.name}  {userSearch.user.ageFormat}  {userSearch.status === 1 && (<span className="new puls">New</span>)} </div>
									<div className="SearchUserText">
									
									<Moment format="D MMMM в h:mm" locale="ru">
										{userSearch.created_at} 
									</Moment>

									</div>
								</div>
								
								<img className="SearchUserAva" src={userSearch.user.photo} />							
	 
							</Card>		

						))}
								
						<Div className='paginations' style={ isDesktop ? { } : { position: 'relative' , bottom: '5px' } }>
							<Pagination 
								style={ !isDesktop ? { position: "static" } : { position: "absolute" }}
								className="naav"
								currentPage={currentPage}
								siblingCount={siblingCount}
								boundaryCount={boundaryCount}
								totalPages={totalPages}
								disabled={disabled}
								onChange={handleChange} 
							/>
						</Div>
					</CardGrid>		  
				)}				 
			</Div> 	 	
		</>
	)
}
 
export default Like;