import React, { useState, useEffect } from 'react';
import { Group, Placeholder, Button } from '@vkontakte/vkui';
import { Icon56ReportOutline } from '@vkontakte/icons';
 
const Error = ({ setPopout, errorText })  => {
	
	// useEffect 
	useEffect(() => {
		
		// Выключить Spinner
		setPopout(null);
 
	}, []);
	
	
    return(
		<>
		<Group>
			<Placeholder
				icon={<Icon56ReportOutline />}
				header="Возникла ошибка"
				action={<Button onClick={()=>window.location.reload()} size="m">Обновить</Button>}
			>
				Ошибка: {errorText}  
			</Placeholder>
	    </Group>
		</>
	); 
}
 
export default Error