import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import 'moment/locale/ru';
import bridge from '@vkontakte/vk-bridge';
import { useAdaptivity, viewWidth, ModalPageHeader, PanelHeaderButton, Separator, Div, Gallery, Button, Caption } from '@vkontakte/vkui';
import { Icon24Cancel, Icon24Fire, Icon24Like, Icon24View, Icon28PictureInPictureOut16x9Outline } from '@vkontakte/icons';
import { useAuth } from '../hooks/auth'

const Vip = ({ setActiveModal }) => {
	
	// Пользователя с бд
	const { user } = useAuth({ middleware: 'guest' }) 
	
	
	// Ширина окна
	const { viewWidth } = useAdaptivity();
	// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
	
	// Кнопка купить vip
	const modalVipPay = () => ( 
 
		bridge.send("VKWebAppOpenPayForm", {"app_id": 51417065, "action": "pay-to-user", "params": {
			"amount": 200 ,
			"description": "Покупка vip аккаунта",
			"user_id": user?.vk ,
		}}) 
		
	);
	
	
	
	return (
		<>
		
		<ModalPageHeader left={ !isDesktop && ( <PanelHeaderButton onClick={() => setActiveModal(null)}> <Icon24Cancel /> </PanelHeaderButton> ) } >
			Vip аккакунт
		</ModalPageHeader>
							
		<Separator />
		
		<Gallery style={{ marginTop: '20px' , fontWeight: '600' , textAlign: 'center' }} timeout="7000" align="center" slideWidth="100%"  >
			<div><Icon24Fire fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} /> Получите уникальный вид <br/> анкеты и значок</div>
			<div><Icon24Like fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} /> Смотрите анкеты тех, <br/> кому вы понравились</div>
			<div><Icon24View fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} /> Смотрите анкеты тех, <br/> кто  посещал вашу анкету</div>
			<div><Icon24Like fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} /> Проявляйте симпатии <br/> без ограничений</div>
			<div><Icon28PictureInPictureOut16x9Outline fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} />Поднять анкету <br/> на первые места в поиске   </div>
		</Gallery> 
						
		{ user?.vipFormat == null ? (
		
			<Div style={{ textAlign: 'center' }}>
 
				<Button onClick={modalVipPay} style={{ marginTop: '10px' }} before={<Icon24Fire style={{ display: 'inline-block' , verticalAlign: 'sub' }} width={18} height={18}  /> } size="l" appearance="accent" stretched>
					Активировать 200р
				</Button> 
				
				<Caption style={{ marginTop:'15px'}} level="3">Vip аккакунт 30 дней</Caption>
				
			</Div>
		
		):(
		
			<Div style={{ textAlign: 'center' }}>
				<div>Vip активирован до</div>
				<b><Moment format="D MMMM YYYY" locale="ru">{user?.vipFormat}</Moment></b>
			</Div>
		
		)}				
		 
		
		</>
	)
}
 
export default Vip;
 