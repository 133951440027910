import React, { useState, useEffect } from 'react';
import { useAdaptivity, viewWidth, ModalPageHeader, PanelHeaderButton, Separator, Div, FormItem, Textarea, Button, FormLayout} from '@vkontakte/vkui';
import { Icon24Cancel, Icon24Fire } from '@vkontakte/icons';
import { useAuth } from '../hooks/auth'

const Messages = ({ setActiveModal, usersId, setSnackbar, setSnackbarText }) => {
	
	const { messages } = useAuth({ middleware: 'guest' })
	
	
	// Ширина окна
	const { viewWidth } = useAdaptivity();
	// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
	
 
	// Id пользователя кому отправляем сообщение
	const [message, setMessage] = useState("");
	
	
	// Клик по форме отправить сообщение
	const submitForm = () => { 
		messages({ usersId, message, setActiveModal, setSnackbar, setSnackbarText }) 
	}
	
	
 
	
	return (
		<>
		
		<ModalPageHeader left={ !isDesktop && ( <PanelHeaderButton onClick={() => setActiveModal(null)}> <Icon24Cancel /> </PanelHeaderButton> ) } >
			Сообщение
		</ModalPageHeader>
							
		<Separator />
		
	 
 						
						
		<Div>
			<form>
				<FormItem top="Новое сообщение">
					<Textarea 
						onChange={event => setMessage(event.target.value)}
						defaultValue={message}
						placeholder="Напишите что-нибудь приятное" 
					/>
				</FormItem>
				
				<FormItem>
				<Button onClick={submitForm} before={<Icon24Fire style={{ display: 'inline-block' , verticalAlign: 'sub' }} width={18} height={18}  /> } style={{ marginTop: '0px' }} size="l" appearance="accent" stretched>
					Отправить
				</Button>
				</FormItem>
			</form>	  
		</Div>
		
		</>
	)
}
 
export default Messages;