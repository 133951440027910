import useSWR from 'swr'
import axios from '../lib/axios'
import { useEffect } from 'react'
 
export const useAuth = ({ middleware } = {}) => {
     
	/// Авторизация
    const { data: user, error, mutate } = useSWR('/api/user', () =>
        axios
            .get('/api/user')
            .then(res => res.data)
            .catch(error => {
				if(error.response.status){
					console.log(error.response.status);
				} 
				if(error.code){
					console.log(error.code); 
				}
            })
    )
 
	/// Регистрация
    const register = async ({ setActivePanel , setSnackbar , setSnackbarText , setErrorText, ...props }) => {
        await axios.get('/sanctum/csrf-cookie')
        axios
            .post('/register', props)
            .then((response) => {
				mutate()
				if(response.data){
					if(response.data.page){
						// Переход после регистрации
						setActivePanel(response.data.page);
					}	
				}	
			})
            .catch(error => {
				if(error.response.data.errors.name){
					// Ошибка имя
					setSnackbar('error');
					setSnackbarText(error.response.data.errors.name[0]);
				}
				if(error.response.data.errors.city){
					// Ошибка город
					setSnackbar('error');
					setSnackbarText(error.response.data.errors.city[0]);
				}
				if(error.response.data.errors.year){
					// Ошибка возраст
					setSnackbar('error');
					setSnackbarText(error.response.data.errors.year[0]);
				}
				if(error.response.status !== 422){
					// Перенаправление на страницу error
					setActivePanel('error');
					// Текст ошибки
					setErrorText(error.response.status);
				}  
            })
    }
	
	
	
	/// Вход
    const login = async ({ params, setActivePanel, setErrorText }) => {
        await axios.get('/sanctum/csrf-cookie')
        axios
            .post('/login', { params })
			.then((response) => {
				mutate()
				if(response.data){
					// Перенаправление на search или register или error
					if(response.data.page){
						setActivePanel(response.data.page)
					}	
				}	
			})
            .catch(error => {
				if(error.response.status){
					// Перенаправление на страницу error
					setActivePanel('error');
					// Текст ошибки
					setErrorText(error.response.status);
				} 
				if(error.code){
					// Перенаправление на страницу error
					setActivePanel('error');
					// Текст ошибки
					setErrorText(error.code);  
				}  
			});
    }
	
	
	
	// Редактировать профиль
    const edit = async ({ name, city, sex, purpose, about, setSnackbar, setSnackbarText }) => {
        await axios.get('/sanctum/csrf-cookie')
        axios
            .post('/api/edit', { name, city, sex, purpose, about })
            .then((response) => {
				mutate()
				if(response.data){
					if(response.data.page == 'success'){
						setSnackbar('success');
						setSnackbarText('Данные сохранены');
					}	
				}	
			})
            .catch(error => {
				if(error.response){
					if(error.response.status){
						// Ошибки
						setSnackbar('error');
						setSnackbarText(error.response.status);
					}
					
					if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							// Ошибки
							setSnackbar('error');
							setSnackbarText(error.response.data.message);
						} 
					}
 	
				}
            })
    }
	
	// Редактировать Анкету
    const questionnaire = async ({ education, children, religion, alcohol, games, smoking, setSnackbar, setSnackbarText }) => {
        await axios.get('/sanctum/csrf-cookie')
        axios
            .post('/api/questionnaire', { education, children, religion, alcohol, games, smoking })
            .then((response) => {
				mutate()
				if(response.data){
					if(response.data.page == 'success'){
						setSnackbar('success');
						setSnackbarText('Данные сохранены');
					}	
				}	
			})
            .catch(error => {
				if(error.response){
					if(error.response.status){
						// Ошибки
						setSnackbar('error');
						setSnackbarText(error.response.status);
					}
					if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							// Ошибки
							setSnackbar('error');
							setSnackbarText(error.response.data.message);
						} 
					}
				}
            })
    }
	
	
	
	
	// Отправить сообщение в профиле
    const messages = async ({ usersId, message, setActiveModal, setSnackbar, setSnackbarText }) => {
        await axios.get('/sanctum/csrf-cookie')
        axios
            .post('/api/messages', { usersId, message })
            .then((response) => {
				mutate()
				if(response.data){
					if(response.data.page == 'success'){
						setActiveModal(null);
						setSnackbar('success');
						setSnackbarText('Сообщение отправлено');
					}	
					if(response.data.page == 'error'){
						setActiveModal(null);
						setSnackbar('error');
						setSnackbarText('Не отправлено');
					}
				}	
			})
            .catch(error => {
				if(error.response){
					if(error.response.status){
						setActiveModal(null);
						// Ошибки
						setSnackbar('error');
						setSnackbarText(error.response.status);
					}
					if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							setActiveModal(null);
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							setActiveModal(null);
							// Ошибки
							setSnackbar('error');
							setSnackbarText(error.response.data.message);
						} 
					}
				}
            })
    }
	
	
	
	
	
	
	
	
	// Загрузить фото
    const photoUpdate = async ({ photoup, setActiveModal, setSnackbar, setSnackbarText }) => {
        await axios.get('/sanctum/csrf-cookie')
        axios
            .post('/api/update_ava', { photoup } , {
				headers: {
				  'Content-Type': 'multipart/form-data'
				},
			})
            .then((response) => {
				mutate()
				if(response.data){
					if(response.data.page == 'success'){
						setActiveModal(null);
						setSnackbar('success');
						setSnackbarText('Загружено');
					}	
				}	
			})
            .catch(error => {
				if(error.response){
					if(error.response.status){
						setActiveModal(null);
						// Ошибки
						setSnackbar('error');
						setSnackbarText(error.response.status);
					}
					if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							setActiveModal(null);
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							setActiveModal(null);
							// Ошибки
							setSnackbar('error');
							setSnackbarText(error.response.data.message);
						} 
					}
				}
            })
    }
	
	
	
 

    const logout = async () => {
        if (! error) {
            await axios
                .post('/logout')
                .then(() => mutate())
        }
		console.log('login') 
    }

    useEffect(() => {
        if (middleware === 'guest' && user)   
        if (middleware === 'auth' && error) logout()
    }, [user, error])




    return {
        user,
        register,
		edit,
		questionnaire,
		messages,
		photoUpdate,
        login,
        logout,
    }
}