import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import axios from '../lib/axios'
import { useAdaptivity, viewWidth, Div, CardGrid, Card, Placeholder, Header ,  Tabbar, TabbarItem, Tooltip} from '@vkontakte/vkui';
import { Icon28SearchLikeOutline, Icon28LikeOutline, Icon28MessageHeartOutline, Icon28UserCircleOutline } from '@vkontakte/icons';
 
 
const Start = ({ setActivePanel, setPopout })  => {
 
	// Ширина окна
	const { viewWidth } = useAdaptivity();
	// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
	
	
	// Tooltip
	const [tooltip, setTooltip] = useState(true); 
	const [tooltip2, setTooltip2] = useState(false);
	const [tooltip3, setTooltip3] = useState(false);
	const [tooltip4, setTooltip4] = useState(false);
	const [tooltip5, setTooltip5] = useState(false);
	
	 
    
	// UseEffect
	useEffect(() => {

		setPopout(null); // Spinner
		setTooltip(true); // Tooltip

	}, []);
 
 
    return(
		<>

		<Tabbar style={isDesktop ? { position:'static' } : { zIndex: "3" }}>
 
				<TabbarItem text="Поиск" > 
					<Tooltip
					header="Поиск"
					text="Настройки поиска можно настройки в профиле."
					isShown={tooltip5}
					onClose={() => {

						setTooltip5(false); 
						setActivePanel("search");
						
					  }}
					offsetX={-9}
					offsetY={25}
					>
						<Icon28SearchLikeOutline />
					</Tooltip>
				</TabbarItem>
 
				<TabbarItem text="Симпатии" > 
					<Tooltip
					header="Симпатии"
					text="Список пользователей которым вы понравились."
					isShown={tooltip4}
					onClose={() => {
						setTooltip4(false);
						setTooltip5(true);
					  }}
					offsetX={-8}
					offsetY={25}
					>
						<Icon28LikeOutline />
					</Tooltip>
				</TabbarItem> 	
 
				<TabbarItem text="Мои гости" > 
					<Tooltip
					header="Мои гости"
					text="Пользователи которые просматривали вашу анкету."
					isShown={tooltip3}
					onClose={() => {
						setTooltip3(false);
						setTooltip4(true);
					  }}
					offsetX={-7}
					offsetY={25}
					>
						<Icon28UserCircleOutline />
					</Tooltip>
				</TabbarItem> 	
 
				<TabbarItem text="Сообщения" > 
					<Tooltip
					header="Сообщения"
					text="Здесь хранятся ваши диалоги с пользователями."
					isShown={tooltip2}
					onClose={() => {
						setTooltip2(false);
						setTooltip3(true);
					  }}
					offsetX={-10}
					offsetY={25}
					>
							<Icon28MessageHeartOutline />
					</Tooltip>
				</TabbarItem> 
 
				<TabbarItem text="Анкета" > 
					<Tooltip
					header="Анкета"
					text="В анкета вы можете отредактировать ваши данные."
					isShown={tooltip}
					onClose={() => {
						setTooltip(false);
						setTooltip2(true);
					  }}
					offsetX={10}
					offsetY={25}
					>
						<Icon28UserCircleOutline />
					</Tooltip>
				</TabbarItem> 
 
			</Tabbar>

		  <Div style={ !isDesktop ? { paddingLeft:"0px" , paddingRight:"0px" } : { paddingTop: '16px' }}>
			<CardGrid size="l">	
				<Card>
					<Placeholder
						header="Начните общаться"  
					>
						Отправьтесь прямо сейчас на поиски общения и знакомства.
					</Placeholder>
				</Card>
		   </CardGrid>	
		</Div>
	 </>
	);
    
}
 
export default Start 