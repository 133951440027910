import React, { useState, useEffect } from 'react';
import { useAdaptivity, viewWidth, ModalPageHeader, PanelHeaderButton, Separator, Div, FormItem, SegmentedControl  } from '@vkontakte/vkui';
import { Icon24Cancel } from '@vkontakte/icons';
import { useAuth } from '../hooks/auth'
 
const Settings = ({ setActiveModal }) => {
	
	
	// Пользователя с бд
	const { user } = useAuth({ middleware: 'guest' })
	
	// Ширина окна
	const { viewWidth } = useAdaptivity();
	// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
	
	
	useEffect(() => {
		 
		// Записываем в хранилище для поиска
		if(localStorage.getItem('SearchSex') === null ){ 
			if(user?.sex == 1){
				localStorage.setItem('SearchSex',"2")
			}
			if(user?.sex == 2){
				localStorage.setItem('SearchSex',"1")
			}
		}
 
	}, []);


 
 

	return (
		<>
		 
		
		<ModalPageHeader left={ !isDesktop && ( <PanelHeaderButton onClick={() => setActiveModal(null)}> <Icon24Cancel /> </PanelHeaderButton> ) } >
			Настройки
		</ModalPageHeader>
							
		<Separator />
		
	 
						
						
		<Div>
		
		 	<FormItem top="Кого искать " >
				<SegmentedControl
					name="sex"
					defaultValue={localStorage.getItem('SearchSex')}
					onChange={(value) => localStorage.setItem('SearchSex',value)}
					options={[
						{
						  label: "Женский",
						  value: "1",
						},
						{
						  label: "Мужской",
						  value: "2",
						},
					]}
				/>
			</FormItem>
			
			<FormItem top="Кого искать " >
				<SegmentedControl
					name="online"
					defaultValue={localStorage.getItem('SearchOnline')}
					onChange={(value) => localStorage.setItem('SearchOnline',value)}
					options={[
						{
						  label: "Все",
						  value: "0",
						},
						{
						  label: "Онлайн",
						  value: "1",
						},
					]}
				/>
			</FormItem>
				  
		</Div>
		
		</>
	)
}
 
export default Settings;