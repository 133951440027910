import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import axios from './lib/axios'
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol, Panel, Snackbar, ModalRoot, ModalPage } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import { Icon28CancelCircleFillRed, Icon28CheckCircleOn } from '@vkontakte/icons';
import { useAuth } from './hooks/auth'
import './App.css';
 
/// Component
import Menu from './component/Menu';
 
/// Page
import Login from './panels/Login';
import Register from './panels/Register';
import Manual from './panels/Manual';
import Search from './panels/Search';
import Like from './panels/Like';
import Visit from './panels/Visit';
import Im from './panels/Im';
import Profil from './panels/Profil';
import User from './panels/User';
import Edit from './panels/Edit';
import Error from './panels/Error';

/// Modal 
import Settings from './modals/Settings';
import Photo from './modals/Photo';
import Vip from './modals/Vip';
import Gifts from './modals/Gifts';
import Messages from './modals/Messages';
 
const App = () => { 

	/// Пользователя с бд
	const { user } = useAuth({ middleware: 'guest' })
 
	/// Активная вкладка
	const [activePanel, setActivePanel] = useState(null);  
	/// Активная всплывающие окно
	const [activeModal, setActiveModal] = useState(null); 
	/// Тема
	const [scheme, setScheme] = useState('bright_light');
	/// Spinner
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	/// Snackbar
	const [snackbar, setSnackbar] = useState(null);
	/// Snackbar Text
	const [snackbarText, setSnackbarText] = useState('Ошибка');
	/// Error Text
	const [errorText, setErrorText] = useState(null);
	/// Id пользователя
	const [usersId, setUsersId] = useState(null);
	/// Id Страницы
	const [usersPage, setUsersPage] = useState(1);
 
 
	// Клик по меню
	const go = (e) => ( 
		setActivePanel(e.currentTarget.dataset.story) 
	);
	
 
	/// Snackbar
	const snackbarBlock =  snackbar &&  
	<Snackbar duration="5000"  onClose={() => setSnackbar(null)} layout='horizontal' action={snackbarText} before={snackbar == 'success' ?  <Icon28CheckCircleOn />  : <Icon28CancelCircleFillRed /> }> 
		{ snackbar == 'success' && <>Успех</> } 
		{ snackbar == 'error' && <>Ошибка</> } 
	</Snackbar>;	
 
 	/// Настройки пользователя
	bridge.subscribe(({ detail: { type, data }}) => {
		if (type === 'VKWebAppUpdateConfig') {
			// Тема пользователя
			setScheme(data.scheme)
		}
	});		
 
	/// useEffect 
	useEffect(() => {

		/// Axios запрос check
		const getСheck = () => {
			axios.get('api/check/'+window.location.search.slice(1))
			.then(response => {
				if(response.data.page){
					// Перенаправление на login или register
					setActivePanel(response.data.page);
				}
			})
			.catch(error => {
				if(error.response.status){
					// Перенаправление на страницу error
					setActivePanel('error');
					// Текст ошибки
					setErrorText(error.response.status);
				} 
				if(error.code){
					// Перенаправление на страницу error
					setActivePanel('error');
					// Текст ошибки
					setErrorText(error.code);  
				}  
			});
		}	
		getСheck()
 
	}, []);
 
	return (
 
		<ConfigProvider scheme={scheme}>
			<AdaptivityProvider>
				<AppRoot scroll="global">
					<SplitLayout popout={popout} modal={
						<ModalRoot activeModal={activeModal} >
 
							<ModalPage id="vip" onClose={() => setActiveModal(null)} >
								<Vip setActiveModal={setActiveModal} ></Vip>
							</ModalPage>
							
							<ModalPage id="settings" onClose={() => setActiveModal(null)} >
								<Settings setActiveModal={setActiveModal} ></Settings>
							</ModalPage>
 
							<ModalPage id="gifts" onClose={() => setActiveModal(null)} >
								<Gifts setActiveModal={setActiveModal} ></Gifts>
							</ModalPage>
							
							<ModalPage id="messages" onClose={() => setActiveModal(null)} >
								<Messages setActiveModal={setActiveModal} usersId={usersId} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} ></Messages>
							</ModalPage>
							
							<ModalPage id="photo" onClose={() => setActiveModal(null)} >
								<Photo setActiveModal={setActiveModal} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText}></Photo>
							</ModalPage>
 
						  </ModalRoot>
						} >
						<SplitCol> 	
								<View activePanel={activePanel}>
										
										<Panel id='login'>  
											<Login setPopout={setPopout} setActivePanel={setActivePanel} setErrorText={setErrorText} />
										</Panel>
										
										<Panel id='register'>
											<Register setPopout={setPopout} setActivePanel={setActivePanel} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} setErrorText={setErrorText} />
											{snackbarBlock}
										</Panel>
 
										<Panel id='manual'>
											<Manual setActivePanel={setActivePanel} setPopout={setPopout} />
											{snackbarBlock}
										</Panel>
 
										<Panel id='search'>
											<Menu useractivePanel={activePanel} setActivePanel={setActivePanel} user={user} />
											<Search go={go} setPopout={setPopout} setActivePanel={setActivePanel} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} setUsersId={setUsersId} setUsersPage={setUsersPage} usersPage={usersPage} setActiveModal={setActiveModal} />
											{snackbarBlock}
										</Panel>
										
										<Panel id='like'>
											<Menu useractivePanel={activePanel} setActivePanel={setActivePanel} user={user} />
											<Like go={go} setPopout={setPopout} setActivePanel={setActivePanel} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} setUsersId={setUsersId} setUsersPage={setUsersPage} usersPage={usersPage} setActiveModal={setActiveModal} />
											{snackbarBlock}
										</Panel>
										 
										<Panel id='visit'>
											<Menu useractivePanel={activePanel} setActivePanel={setActivePanel} user={user} />
											<Visit go={go} setPopout={setPopout} setActivePanel={setActivePanel} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} setUsersId={setUsersId} setUsersPage={setUsersPage} usersPage={usersPage} setActiveModal={setActiveModal} />
											{snackbarBlock}
										</Panel>
										
										<Panel id='im'>
											<Menu useractivePanel={activePanel} setActivePanel={setActivePanel} user={user} />
											<Im go={go} setPopout={setPopout} setActivePanel={setActivePanel} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} setUsersId={setUsersId} setUsersPage={setUsersPage} usersPage={usersPage} />
											{snackbarBlock}
										</Panel>
 
										<Panel id='profil'>
											<Menu useractivePanel={activePanel} setActivePanel={setActivePanel} user={user} />
											<Profil user={user} setPopout={setPopout} setActivePanel={setActivePanel} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} setActiveModal={setActiveModal}/>
											{snackbarBlock}
										</Panel>
										
										<Panel id='user'>
											<User setPopout={setPopout} setActivePanel={setActivePanel} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} usersId={usersId} usersPage={usersPage} setUsersPage={setUsersPage} setActiveModal={setActiveModal} />
											{snackbarBlock}
										</Panel>
										
										<Panel id='edit'>
											<Edit go={go} setPopout={setPopout} setActivePanel={setActivePanel} setSnackbar={setSnackbar} setSnackbarText={setSnackbarText} />
											{snackbarBlock}
										</Panel>
										
										<Panel id='error'>
											<Error setPopout={setPopout} errorText={errorText} />
										</Panel>
	 
 
								</View> 
						</SplitCol> 		
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App; 