import React, { useState, useEffect } from 'react';
import axios from '../lib/axios'
import { useAdaptivity, viewWidth , Snackbar, Banner, ScreenSpinner, Div, Panel, PanelHeader, PanelHeaderBack, Group,  Avatar, Placeholder, Button, Text, CardGrid, Card, FormLayout, FormItem, Input, Select, Textarea,  Progress, Header, SimpleCell, InfoRow} from '@vkontakte/vkui';
import { Icon28ArticleOutline, Icon36LikeOutline, Icon36CancelOutline, Icon24Gift,  Icon20FireCircleFillRed, Icon20RadioOn, Icon28LikeCircleFillRed, Icon28CancelCircleFillRed, Icon28CheckCircleOn } from '@vkontakte/icons';


const User = ({ go , setPopout , setActivePanel , setSnackbar , setSnackbarText , usersId , usersPage , setUsersPage , setActiveModal }) => {
 
	const { viewWidth } = useAdaptivity(); // Ширина окна
    const isDesktop = viewWidth > '2'; // Размер экрана для ПК версии
	const [users, setUsers] = useState([]); // Данные пользователя
	const [like, setLike] = useState(false); // Кнопка Like
	const [Nolike, setNoLike] = useState(false); // Кнопка Like No
 
	const [name, setName] = useState('Не указано');  // Имя
	const [city, setCity] = useState('Не указано');  // Город
	const [photo, setPhoto] = useState('https://vk.com/images/camera_400.png'); // Фото
	const [onlineFormat, setOnlineFormat] = useState(null);	// Онлайн
	const [vipFormat, setVipFormat] = useState(null); // Vip
	const [ageFormat, setAgeFormat]= useState('Не указано'); // Возраст
	const [purpose, setPurpose] = useState('Не указано'); // Цель
	const [about, setAbout] = useState('Не указано'); // Обо мне
	const [sex, setSex] = useState(2); // Пол
	const [badHabits, setBadHabits] = useState('Не указано'); // Вредные привычки 
	const [education, setEducation] = useState('Не указано'); // Образование
	const [children, setChildren] = useState('Не указано'); // Дети
	const [religion, setReligion] = useState('Не указано'); // Религия
	const [counter, setCounter] = useState(40); // Количество заполненной анкеты
 
	const onStoryChangePanelHeaderBack = (e) => ( // Кнопка Назад  
		setActivePanel("search"), // Записываем страницу
		setUsersPage(usersPage) // Записываем номер страницы  
	);
	
	
	// Кнопка отправить сообщение
	const modalMessages = () => ( 
		setActiveModal('messages')
	);
	
	
	
	const csrf = () => axios.get('/sanctum/csrf-cookie') // CSRF
	const fetchData = async (usersId) => { // Axios запрос о пользователе 
	setPopout(<ScreenSpinner size='large' />); // Включить Spinner
	await csrf()
	axios
		.get('/api/users/'+usersId+'')
		.then(response => {
			setUsers(response.data); // Пользователь
			setName(response.data.name); 
			setCity(response.data.city); 
			setOnlineFormat(response.data.onlineFormat); 
			setVipFormat(response.data.vipFormat); 
			setAgeFormat(response.data.ageFormat); 
			if(response.data.purpose == 1){setPurpose('Общения и флирт');}
			if(response.data.purpose == 2){setPurpose('Серьезные знакомства');}
			if(response.data.purpose == 3){setPurpose('Секс знакомства');}
			setSex(response.data.sex); 
			
			// Фото
			if(response.data.photo){  
				setPhoto(response.data.photo);
				setCounter(counter => counter + 10);
			} 		
			// Обо мне
			if(response.data.about){  
				setAbout(response.data.about); 
				setCounter(counter => counter + 10);
			} 
			// Образование
			if(response.data.education){  
				setEducation(response.data.education); 
				setCounter(counter => counter + 10);
			}
			// Дети
			if(response.data.children){  
				setChildren(response.data.children);  
				setCounter(counter => counter + 10);
			}
			// Религия
			if(response.data.religion){  
				setReligion(response.data.religion); 
				setCounter(counter => counter + 10);
			}		 
			// Вредные привычки 
			if(response.data.alcohol || response.data.games || response.data.smoking){
				setBadHabits('' + response.data.alcohol + ' ' + response.data.games + ' ' + response.data.smoking + ''); 
				setCounter(counter => counter + 10);
			}
 
			setLike(response.data.Like); // Like
			setNoLike(response.data.LikeNo); // Like No		
			setPopout(null);  // Выключить Spinner
		})
		.catch(error => {
			if(error.response.status){
				if(error.response.status == 401){
					setSnackbar('error'); // Error	
					setSnackbarText('Пользователь не авторизован'); // Error Text
				}else{
					setSnackbar('error'); // Error	
					setSnackbarText('Ошибка: ' + error.response.status); // Error Text
				} 
			} 
			if(error.code == 'ERR_NETWORK'){
				setSnackbar('error'); // Error	
				setSnackbarText('Доступ в Интернет закрыт'); // Error Text
			}
			if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Ошибка: ' + error.response.data.message);
						} 
					}
			setPopout(null);  // Выключить Spinner 
		});
	} 
 
	 
	const onStoryChangeLike = (e) =>  { // Кнопка Like
		setPopout(<ScreenSpinner size='large' />); // Включить Spinner 
		csrf() 
		axios
			.get('/api/like/add/'+usersId+'')
			.then(response => {
			  
			if(response.data.page == 'ok'){
				setLike(true); // Like
				setNoLike(false); // Like No
				setSnackbar('success'); // Success	
				setSnackbarText(sex == 1 ? ( "Вам понравилась " + name ) : ( "Вам понравился " + name ) ); // Success Text
			}
			if(response.data.page == 'limit'){
				setSnackbar('error'); // Success	
				setSnackbarText('Лимит 10 симпатии в сутки'); // Success Text
			}	
					
			 
			setPopout(null);  // Выключить Spinner
			})
			.catch(error => {
				if(error.response.status){
					if(error.response.status == 401){
						setSnackbar('error'); // Error	
						setSnackbarText('Пользователь не авторизован'); // Error Text
					}else{
						setSnackbar('error'); // Error	
						setSnackbarText('Ошибка: ' + error.response.status); // Error Text
					} 
				} 
				if(error.code == 'ERR_NETWORK'){
					setSnackbar('error'); // Error	
					setSnackbarText('Доступ в Интернет закрыт'); // Error Text
				}
				if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Ошибка: ' + error.response.data.message);
						} 
					}
				setPopout(null);  // Выключить Spinner 
			});
	} 
	
	// No Like
	const onStoryChangeNoLike = (e) => {
		// Включить Spinner
		setPopout(<ScreenSpinner size='large' />);  
		// Axios Like No
		csrf() 
		axios
			.get('/api/like/no/'+usersId+'')
			.then(response => {
				setLike(false); // Like
				setNoLike(true); // Like No
				setSnackbar('success'); // Success	
				setSnackbarText("Вам не нравится  " + name); // Success Text
				setPopout(null);  // Выключить Spinner
			})
		    .catch(error => {
				if(error.response.status){
					if(error.response.status == 401){
						setSnackbar('error'); // Error	
						setSnackbarText('Пользователь не авторизован'); // Error Text
					}else{
						setSnackbar('error'); // Error	
						setSnackbarText('Ошибка: ' + error.response.status); // Error Text
					} 
				} 
				if(error.code == 'ERR_NETWORK'){
					setSnackbar('error'); // Error	
					setSnackbarText('Доступ в Интернет закрыт'); // Error Text
				}
				if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Ошибка: ' + error.response.data.message);
						} 
					}
				setPopout(null);  // Выключить Spinner 
			});
	}
 
	// При первой загрузки
	useEffect(() => {
		fetchData(usersId); // Axios запрос
	}, [])
	
	
  
return (
	<>
		
		<PanelHeader separator={false} left={ <PanelHeaderBack onClick={onStoryChangePanelHeaderBack} /> } >
			{name}
		</PanelHeader>
		
 
		<Div style={ !isDesktop ? { paddingLeft:"0px" , paddingRight:"0px" } : { paddingTop: '16px' }}>
		
			<CardGrid style={ isDesktop ? { } : { padding: '0px 6px' }} size={isDesktop ? ( "m" ) : ( "l" )} >	

				<Card>			 
					<Div style={{ position: 'relative' }} >		
						{vipFormat != null && ( <Icon20FireCircleFillRed className="UserVip puls"/>)}
						 
						{onlineFormat == "online" && ( <Icon20RadioOn fill="#11c705" className="puls UserOnline"/>)}
						{onlineFormat == "offline" && ( <Icon20RadioOn fill="#adadad" className="puls UserOnline"/>)}
						
						<img style={{ objectFit: "cover" , width: "100%" , height: "396px" , marginBottom: "10px" , borderRadius: "7px" ,  marginTop: "5px" }} src={photo} />
						
						<div className="bar_posit">
							<div className="bar_flex">
								
								{ like == true ? ( 
									<div className="bar_flex_icons" style={{ marginLeft: "5px" }}><Icon28LikeCircleFillRed fill="#ffffff" width={46} height={46} /></div>
								) : ( 
									<div onClick={onStoryChangeLike} className="bar_flex_icons" style={{ marginLeft: "5px" }}><Icon36LikeOutline className="puls" fill="#ffffff" width={46} height={46} /></div>								
								)}
		 
								<div className="bar_flex_icons"> <Icon24Gift width={30} height={30} fill="#ffffff" /></div>
 
								{ Nolike == true ? ( 
									<div className="bar_flex_icons" style={{ marginLeft: "5px" }}><Icon28CancelCircleFillRed fill="#ffffff" width={46} height={46} /></div>
								) : ( 
									<div onClick={onStoryChangeNoLike} className="bar_flex_icons" style={{ marginLeft: "5px" }}><Icon36CancelOutline className="puls" fill="#ffffff" width={46} height={46} /></div>								
								)}
							
							
							</div>
						</div>
 
					</Div>
					
				 

					<Div style={{ paddingTop: '0px' }}>
						<Progress style={{ height: '3px' , padding: '2px' , marginTop: '0px' , marginBottom: '0px'}} aria-labelledby="progresslabel" value={counter} /> 
					</Div>
					
					<Div style={{ paddingTop: '10px' }}>
						<Button onClick={modalMessages} data-story={usersId} size="l" appearance="accent" stretched>
							Написать сообщение
						</Button>
					</Div>
						
						 
				</Card>

				<Card>
					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Имя">{name}</InfoRow>
					</SimpleCell>
							  
					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Город">{city}</InfoRow>
					</SimpleCell>
							  
					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Возраст ">{ageFormat}</InfoRow>
					</SimpleCell>
							  
					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Цель знакомства">{purpose}</InfoRow>
					</SimpleCell>

					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Немного о себе">
							{about}
						</InfoRow>
					</SimpleCell>
					
					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Образование">
							{education}
						</InfoRow>
					</SimpleCell>
					
					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Дети">
							{children}
						</InfoRow>
					</SimpleCell>
					
					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Религия">
							{religion}
						</InfoRow>
					</SimpleCell>
					
					<SimpleCell disabled before={<Icon28ArticleOutline />} >
						<InfoRow header="Вредные привычки">
						{badHabits}
						</InfoRow>
					</SimpleCell>
					
				 
			 
					 
 
			 
				</Card>		
				
				 
 	
			</CardGrid>		

			 
		</Div>	 	
	</>
  )
}

export default User;
 