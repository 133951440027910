import React, { useState, useEffect } from 'react';
import axios from '../lib/axios'
import { useAdaptivity, ScreenSpinner, Div, PanelHeader, Group,  Avatar, Placeholder, Button, Banner, Text, CardGrid, Card, Pagination } from '@vkontakte/vkui';
import { Icon20RadioOn, Icon56SearchLikeOutline, Icon20FireCircleFillRed, Icon12Circle } from '@vkontakte/icons';
 
const Search = ({ go , setPopout , setActivePanel , setSnackbar , setSnackbarText , setUsersId , setUsersPage , usersPage, setActiveModal }) => {
 
	const { viewWidth } = useAdaptivity(); // Ширина окна
    const isDesktop = viewWidth > '2'; // Размер экрана для ПК версии

	const [currentPage, setCurrentPage] = useState(usersPage); // Текущая страница 
	const [siblingCount, setSiblingCount] = useState(1); // Кол-во всегда видимых страниц по краям текущей страницы 
	const [boundaryCount, setBoundaryCount] = useState(1); // Кол-во всегда видимых страниц в начале и в конце 
	const [totalPages, setTotalPages] = useState(1); // Общее кол-во страниц 
	const [disabled, setDisabled] = useState(false); // Блокировка всех кнопок 
 
	const [users, setUsers] = useState([]); // Список пользователей
 
	// Pagination Клик  
	const handleChange = React.useCallback((page) => {  
		setCurrentPage(page); // Текущая страница 
		fetchDataSearch(page); // Axios запрос
	},[]);
	
	// Клик по пользователею
	const goUser = (e) => {  
		setActivePanel('user') , // Имя Страницы
		setUsersPage(currentPage) , // Id Страницы
		setUsersId(e.currentTarget.dataset.id) // Id пользователя 
	}
	
	// Кнопка Настройки модальное окно
	const modalSettings = () => ( 
		setActiveModal('settings')
	);
	
	
	
	
	
 
	
	
	// Axios запрос Search
	const fetchDataSearch = async (currentPage) => {  
	setPopout(<ScreenSpinner size='large' />); // Включить Spinner
	axios
		.get('/api/search?page='+currentPage+'&sex='+localStorage.getItem('SearchSex')+'&online='+localStorage.getItem('SearchOnline'))
		.then(response => {
			setUsers(response.data.data); // Пользователи
			setCurrentPage(response.data.current_page); // Текущая страница. 
			setTotalPages(response.data.last_page); // Общее кол-во страниц. 
			setPopout(null); // Spinner   
		})
		.catch(error => {
			if(error.response.status){
				if(error.response.status == 401){
					setSnackbar('error'); // Error	
					setSnackbarText('Пользователь не авторизован'); // Error Text
				}else{
					setSnackbar('error'); // Error	
					setSnackbarText('Ошибка: ' + error.response.status); // Error Text
				} 
			} 
			if(error.code == 'ERR_NETWORK'){
				setSnackbar('error'); // Error	
				setSnackbarText('Доступ в Интернет закрыт'); // Error Text
			}
			if(error.response.data.message){
						if(error.response.data.message == "Too Many Attempts"){
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Слишком много Попыток');
						}else{
							// Ошибки
							setSnackbar('error');
							setSnackbarText('Ошибка: ' + error.response.data.message);
						} 
					}
			setPopout(null);  // Выключить Spinner 
		});
	}  
 
	useEffect(() => { // UseEffect
  
		fetchDataSearch(currentPage); // Axios запрос Search
		setUsersPage(1); // Первая страница search
 
	}, []);
 
 
	return (
		<>
		{ !isDesktop && ( <PanelHeader>Поиск</PanelHeader> )}
			<Div style={ isDesktop ? { } : { marginBottom: '20px', paddingLeft: '0px' , paddingRight: '0px' }} >
				{users.length ? (  		
					<CardGrid style={ isDesktop ? { } : { padding: '0px 6px' }} size={ isDesktop ? ( "s" ) : ( "m" )}  >	
  
						{users.map(user => ( 
							
							<Card key={user.id} onClick={goUser} data-id={user.id} className="SearchUserCard" >

								{user.vipFormat != null && ( <Icon20FireCircleFillRed className="SearchUserVip puls"/> ) }  
								{user.onlineFormat == "online" && ( <Icon20RadioOn fill="#11c705" className="puls SearchUserOnline"/> ) } 
								{user.onlineFormat == "offline" && ( <Icon20RadioOn fill="#adadad" className="puls SearchUserOnline"/> ) } 

								<div className="SearchUserName">
									<div> {user.name} {user.ageFormat} </div>
									<div className="SearchUserText"> {user.city} </div>
								</div>
								
								<img className="SearchUserAva" src={user.photo} />
								
							 
							</Card>
						))}
								
						<Div className='paginations' style={ isDesktop ? { } : { position: 'relative' , bottom: '5px' } }>
							<Pagination 
								style={ !isDesktop ? { position: "static" } : { position: "absolute" }}
								className="naav"
								currentPage={currentPage}
								siblingCount={siblingCount}
								boundaryCount={boundaryCount}
								totalPages={totalPages}
								disabled={disabled}
								onChange={handleChange} 
							/>
						</Div>
					</CardGrid>	
 		
				):( 
				
					<Card>
						<Placeholder
							header="Анкеты не найдены"
							icon={<Icon56SearchLikeOutline width={56} height={56} />}
							action={<Button onClick={modalSettings} data-story="profil" size="l">Настроить фильтр поиска</Button>}
						>
							Измените фильтр или зайдите позже
						</Placeholder>
					</Card>
					
					
					 	  
				)}				 
			</Div> 	 	
		</>
	)
}
 
export default Search;