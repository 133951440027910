import React, { useState, useEffect } from 'react';
import { Spacing, Separator, Tappable, IconButton, Subhead, Gallery, ButtonGroup, UsersStack, useAdaptivity, viewWidth, CellButton, Div, Panel, PanelHeader, Group,  Avatar, Placeholder, Button, Banner, Text, CardGrid, Card, FormLayout, FormItem, Input, Select, Textarea,  Progress, Header, SimpleCell, InfoRow} from '@vkontakte/vkui';
import { Icon20RadioOn, Icon24Settings, Icon20FireCircleFillRed, Icon24Camera, Icon28CancelCircleFillRed, Icon28LikeCircleFillRed, Icon28ArrowUpRectangle, Icon28PictureInPictureOut16x9Outline, Icon24Like, Icon28ArticleOutline, Icon24Fire, Icon24View, Icon28AddOutline, Icon28Write, Icon24UserSquare, Icon24SearchLikeFilledOutline} from '@vkontakte/icons';
 
const Profil = (props) => {
 
	// Ширина окна
	const { viewWidth } = useAdaptivity();
	// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
 
	// UseEffect
	useEffect(() => {
 
		props.setPopout(null); // Spinner
		 
	}, []);
 
	return ( 
	<>
		{ !isDesktop && ( <PanelHeader>Анкета</PanelHeader> )}
		<Div style={ isDesktop ? { } : { marginBottom:'50px', paddingLeft: "0px" , paddingRight: "0px" } }>
			<CardGrid size={isDesktop ? ( "m" ) : ( "l" )} style={ isDesktop ? { } : { padding: '0px 6px' }} >	
				 <Card>			 
					<Div style={{ position: 'relative' }} >	
						
						{props.user?.vip != null && ( <Icon20FireCircleFillRed className="UserVip puls"/>)}
						<Icon20RadioOn fill="#11c705" className="puls UserOnline"/>
						<img style={{ objectFit: "cover" , width: "100%" , height: "341px" , marginBottom: "10px" , borderRadius: "7px" ,  marginTop: "5px" }} src={props.user?.photo} />
						
						<div style={{ bottom: '210px' }} className="bar_posit">
							<div className="bar_flex">
								<div onClick={()=>props.setActiveModal('settings')} data-story="edit" className="bar_flex_icons" style={{ marginLeft: "5px" }}><IconButton><Icon24Settings fill="#fff" width={28} height={28}  />  </IconButton></div>
								<div onClick={()=>props.setActiveModal('photo')} className="bar_flex_icons"> <Icon24Camera width={30} height={30} fill="#ffffff" /></div>
								<div onClick={()=>props.setActivePanel('edit')} className="bar_flex_icons" style={{ marginLeft: "5px" }}><IconButton><Icon28Write fill="#fff" /> </IconButton></div>
							</div>
						</div>
						
						<Button onClick={()=>props.setActiveModal('vip')} before={<Icon24Fire style={{ display: 'inline-block' , verticalAlign: 'sub' }} width={18} height={18}  /> } style={{ marginTop: '0px' }} size="l" appearance="accent" stretched>
							Стать популярней
						</Button>
 
						<Spacing size={24}>
							<Separator />
						</Spacing>	
 
						<Subhead level="2" style={{ marginTop: '5px' }}> <div style={{ display: 'flex' , justifyContent: 'space-between' }} ><span className="rating">Популярность  </span> <span className="rating"> {props.user?.star}% </span></div>   </Subhead>
						<Progress style={{ padding: '1px' }} aria-labelledby="progresslabel" value={props.user?.star} />
 
						<Subhead level="2" style={{ marginTop: '5px' }}><div style={{ display: 'flex' , justifyContent: 'space-between' }} ><span className="rating">Анкета заполнена </span> <span className="rating"> 10%</span></div>   </Subhead>
						<Progress style={{ padding: '1px' }} aria-labelledby="progresslabel3" value='10' />
 
						<Subhead level="2" style={{ marginTop: '5px' }}><div style={{ display: 'flex' , justifyContent: 'space-between' }} ><span className="rating">{ props.user?.vip ? ( <span>Беслимитные симпатии</span> ) : ( <span>Лимит симпатий за сутки</span> ) }  </span> <span className="rating"> {props.user?.likeDay}%</span></div>   </Subhead>
						<Progress style={{ padding: '1px' }} aria-labelledby="progresslabel3" value={props.user?.likeDay} />
 
						<Spacing size={10}>
							<Separator />
						</Spacing>	 
 
					</Div>
				</Card>
				 
 
				<Card>
					<Div>
						<SimpleCell style={{ paddingLeft: '0px' }} disabled before={<Icon28ArticleOutline />} >
							<InfoRow header="Имя">{props.user?.name ? ( props.user?.name ) : ( <span>Нет информации</span> ) } </InfoRow>
						</SimpleCell>
							
						<SimpleCell style={{ paddingLeft: '0px' }} disabled before={<Icon28ArticleOutline />} >
							<InfoRow header="Город">{props.user?.city ? ( props.user?.city ) : ( <span>Нет информации</span> ) } </InfoRow>
						</SimpleCell>
							
						<SimpleCell style={{ paddingLeft: '0px' }} disabled before={<Icon28ArticleOutline />} >
							<InfoRow header="Возраст"> {props.user?.ageFormat ? ( props.user?.ageFormat ) : ( <span>Нет информации</span> ) } </InfoRow>
						</SimpleCell>
							
						<SimpleCell style={{ paddingLeft: '0px' }} disabled before={<Icon28ArticleOutline />} >
							<InfoRow header="Цель знаковства"> {props.user?.purposeFormat ? ( props.user?.purposeFormat ) : ( <span>Нет информации</span> ) } </InfoRow>
						</SimpleCell>
 
						<SimpleCell style={{ paddingLeft: '0px' }} disabled before={<Icon28ArticleOutline />} >
							<InfoRow header="Образование"> {props.user?.education ? ( props.user?.education ) : ( <span>Нет информации</span> ) } </InfoRow>
						</SimpleCell>
							
						<SimpleCell style={{ paddingLeft: '0px' }} disabled before={<Icon28ArticleOutline />} >
							<InfoRow header="Дети"> {props.user?.children ? ( props.user?.children ) : ( <span>Нет информации</span> ) } </InfoRow>
						</SimpleCell>
							
						<SimpleCell style={{ paddingLeft: '0px' }} disabled before={<Icon28ArticleOutline />} >
							<InfoRow header="Религия"> {props.user?.religion ? ( props.user?.religion ) : ( <span>Нет информации</span> ) } </InfoRow>
						</SimpleCell>
 
						<Spacing size={10}>
							<Separator />
						</Spacing>		
						
						<Gallery style={{ marginTop: '0px' , fontWeight: '600' , textAlign: 'center' }} timeout="7000" align="center" slideWidth="100%"  >
							<div><Icon24Fire fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} /> Получите уникальный вид <br/> анкеты и значок</div>
							<div><Icon24Like fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} /> Смотрите анкеты тех, <br/> кому вы понравились</div>
							<div><Icon24View fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} /> Смотрите анкеты тех, <br/> кто  посещал вашу анкету</div>
							<div><Icon24Like fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} /> Проявляйте симпатии <br/> без ограничений</div>
							<div><Icon28PictureInPictureOut16x9Outline fill="#d55151" width={40} height={40} style={{ margin: 'auto' }} />Поднять анкету <br/> на первые места в поиске   </div>
						</Gallery> 
 
					</Div>
				</Card>		
			</CardGrid>			
		</Div>	 	
	</>
	)
}
 
export default Profil;




 