import React, { useState, useEffect } from 'react';
import { useAdaptivity, viewWidth, ModalPageHeader, PanelHeaderButton, Separator, Div, FormItem, File, Button, Caption } from '@vkontakte/vkui';
import { Icon24Cancel, Icon24Document } from '@vkontakte/icons';
import { useAuth } from '../hooks/auth'
 
const Photo = ({ setActiveModal, setSnackbar, setSnackbarText }) => {
	
	
	const { photoUpdate } = useAuth({ middleware: 'guest' })
	
	
	// Ширина окна
	const { viewWidth } = useAdaptivity();
	// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
	
	const [photoup, setPhotoUp] = useState("");
	
	// Клик по форме загрузить фото
	const submitFormPhoto = () => { 
		photoUpdate({ photoup, setActiveModal, setSnackbar, setSnackbarText }) 
	}
	
	
	console.log(photoup);
	
	
	return (
		<>
		
		<ModalPageHeader left={ !isDesktop && ( <PanelHeaderButton onClick={() => setActiveModal(null)}> <Icon24Cancel /> </PanelHeaderButton> ) } >
			Изменить фото
		</ModalPageHeader>
							
		<Separator />
		
		 
			<Div style={{ textAlign: 'center' }}>				
				<FormItem top="Загрузите настоящую фотографию">
					<File
						onChange={(e) => setPhotoUp(e.target.files[0])}
	 
					 
						before={<Icon24Document role="presentation" />}
						size="l"
						mode="secondary"
					/>
				</FormItem>
				
				<Caption  level="3">Можете загрузить изображение<br/> в формате <b>JPG, JPEG</b> не более <b>1мб</b></Caption>
				 
				<FormItem style={{ marginTop: '5px' }} >
					<Button 
						onClick={submitFormPhoto}
						size="l" 
						appearance="accent" 
						stretched
					>
						Загрузить
					</Button>
				</FormItem>	
			</Div>
		
		</>
	)
}
 
export default Photo;