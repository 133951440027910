import React, { useState, useEffect } from 'react';
import axios from '../lib/axios'
import { useAdaptivity, ScreenSpinner, Div, PanelHeader, Group,  Avatar, Placeholder, Button, Banner, Text, CardGrid, Card, Pagination } from '@vkontakte/vkui';
import { Icon56SearchLikeOutline, Icon20FireCircleFillRed, Icon12Circle } from '@vkontakte/icons';
 
const Im = (props) => {
	
	const { viewWidth } = useAdaptivity(); // Ширина окна
    const isDesktop = viewWidth > '2'; // Размер экрана для ПК версии
	
	
	console.log('Im ' + props.userAuth);
	
	return (
		<>
		{ !isDesktop && ( <PanelHeader>Сообщение</PanelHeader> )}
			<Div style={ isDesktop ? { } : { marginBottom:'20px', paddingLeft: "0px" , paddingRight: "0px" } }>
				
				<Card>
					<Placeholder
						header="Сообщение не найдены"
						icon={<Icon56SearchLikeOutline width={56} height={56} />}
						action={<Button onClick={()=>props.setActivePanel('search')} size="l">Перейти в поиск</Button>}
					>
						временно недоступны
					</Placeholder>
				</Card> 	 
			</Div> 	 	
		</>
	)
}
 
export default Im;
 