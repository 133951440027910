 
const Gifts = () => {

	return (
		<div>
		 	Gifts
		</div>
	)
}
 
export default Gifts;