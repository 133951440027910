import React, { useState, useEffect } from 'react';
import axios from '../lib/axios'
import { CustomSelect, useAdaptivity, viewWidth , Snackbar, Checkbox, Banner, ScreenSpinner, Div, Panel, PanelHeader, PanelHeaderBack, Group,  Avatar, Placeholder, Button, Text, CardGrid, Card, FormLayout, FormItem, Input, DatePicker, Select, Textarea,  Progress, Header, SimpleCell, InfoRow} from '@vkontakte/vkui';
import { Icon28Write, Icon28ArticleOutline, Icon36LikeOutline, Icon36CancelOutline, Icon24Gift,  Icon20FireCircleFillRed, Icon20RadioOn, Icon28LikeCircleFillRed, Icon28CancelCircleFillRed, Icon28CheckCircleOn } from '@vkontakte/icons';
import { useAuth } from '../hooks/auth'
  
const Edit = ({ go, setPopout , setActivePanel , setSnackbar , setSnackbarText }) => {
	
	// Пользователя с бд
	const { user } = useAuth({ middleware: 'guest' })
	const { edit } = useAuth({ middleware: 'guest' })	
	const { questionnaire } = useAuth({ middleware: 'guest' })
	
	
	const { viewWidth } = useAdaptivity(); // Ширина окна
    const isDesktop = viewWidth > '2'; // Размер экрана для ПК версии
 
	// Поля с формы
	const [name, setName] = useState('Не указано');
	const [sex, setSex] = useState('2');
	const [purpose, setPurpose] = useState('1');
	const [about, setAbout] = useState('Не указано');
	
	const [alcohol, setAlcohol] = useState(0);
	const [games, setGames] = useState(0);
	const [smoking, setSmoking] = useState(0);
 
	const [education, setEducation] = useState(0);
	const [children, setChildren] = useState(0);
	const [religion, setReligion] = useState(0);
 
 
	// Клик по форме
	const submitForm = () => { 
		edit({ name, sex, purpose, about, setSnackbar, setSnackbarText }) 
	}
	
	// Клик по форме Анкета
	const submitFormQuestionnaire = () => { 
		questionnaire({ education, children, religion, setSnackbar, alcohol, games, smoking, setSnackbarText }) 
	}
	 
	 
 
  
  
  
	 
	// UseEffect
	useEffect(() => {
		
		if(user){
			setName(user?.name);
			setSex('' + user?.sex + '');
			setPurpose('' + user?.purpose + '');
			setAbout(user?.about);
 
			// Анкета
			if(user?.questionnaire){ 
				setEducation('' + user?.questionnaire.education + '');  
				setChildren('' + user?.questionnaire.children + '');
				setReligion('' + user?.questionnaire.religion + '');
				if(user?.questionnaire.alcohol){ setAlcohol('1'); }
				if(user?.questionnaire.games){ setGames('1'); }
				if(user?.questionnaire.smoking){ setSmoking('1'); }
			}
		}else{
			setSnackbar('error'); // Error	
			setSnackbarText('Пользователь не авторизован'); // Error Text
		}
 
	}, []);
  
 
 
 
return (
	<>
		
		<PanelHeader separator={false} left={ <PanelHeaderBack onClick={go} data-story="profil" /> } >
			Редактировать
		</PanelHeader>
		
 
		<Div style={ !isDesktop ? { paddingLeft:"0px" , paddingRight:"0px" } : { }}>
		
			<CardGrid size={isDesktop ? ( "m" ) : ( "l" )} >	

				<Card>			 
 
					<form>
						<FormItem style={{ paddingBottom: '5px' }} top="Ваше имя">
							<Input 
								type="text" 
								placeholder="Ваше имя" 
								onChange={event => setName(event.target.value)}
								value={name}
								required 
								autoFocus
							/>
						</FormItem>
 
						<FormItem style={{ paddingBottom: '5px' }} top="Ваше пол">
							<Select 
								onChange={event => setSex(event.target.value)}
								placeholder="Мужской"
								value={sex}
								required
								options={[
									{
										value: '1',
										label: "Женский", 
									},
									{   
										value: '2',
										label: "Мужской",  
									},
								]}
							/>
						</FormItem>
									
						<FormItem style={{ paddingBottom: '5px' }} top="Цель знакомства">
							<Select 
								onChange={event => setPurpose(event.target.value)} 
								placeholder="Общения и флирт" 
								value={purpose}
								required
								options={[
									{
										value: "1",
										label: "Общения и флирт",
									},
									{ 
										  
										value: "2",
										label: "Серьезные знакомства",
									},
									{
										 
										value: "3",
										label: "Секс знакомства",
									},
								]}
							/>
						</FormItem>
						
						
						<FormItem style={{ paddingBottom: '5px' }} top="Немного о себе">
							<Input 
								type="text" 
								placeholder="Немного о себе" 
								onChange={event => setAbout(event.target.value)}
								value={about}
								required 
								autoFocus
								/>
						</FormItem>
								  

 
						<FormItem style={{ marginTop: '5px' }} >
							<Button 
								before={<Icon28Write style={{ display: 'inline-block' , verticalAlign: 'sub' }} width={20} height={20}  /> } 
								onClick={submitForm}
								size="l" 
								appearance="accent" 
								stretched
							>
								Сохранить
							</Button>
						</FormItem>	


					</form>		
						
				</Card>

				<Card>
				
					<form>
						<FormItem style={{ paddingBottom: '5px' }} top="Образование">
							<Select 
								onChange={event => setEducation(event.target.value)}
								placeholder="Не важно"
								value={education}
								required
								options={[
									{
										value: '0',
										label: "Не важно", 
									},
									{   
										value: '1',
										label: "Среднее",  
									},
									{   
										value: '2',
										label: "Средне-техническое",  
									},
									{   
										value: '3',
										label: "Высшее",  
									},
									{   
										value: '4',
										label: "Ученая степень",  
									},
									{   
										value: '5',
										label: "Младший специалист",  
									},
									{   
										value: '6',
										label: "Бакалавр",  
									},
									{   
										value: '7',
										label: "Магистр",  
									},
									{   
										value: '8',
										label: "Специалист",  
									},
									{   
										value: '9',
										label: "Неполное",  
									},
								]}
							/>
						</FormItem>
						<FormItem style={{ paddingBottom: '5px' }} top="Дети">
							<Select 
								onChange={event => setChildren(event.target.value)}
								placeholder="Не важно"
								value={children}
								required				
								options={[
									{
										value: '0',
										label: "Не важно", 
									},
									{   
										value: '1',
										label: "Нет",  
									},
									{   
										value: '2',
										label: "Нет, но хотелось бы",  
									},
									{   
										value: '3',
										label: "Мужской",  
									},
									{   
										value: '4',
										label: "Есть, живем вместе",  
									},
									{   
										value: '5',
										label: "Есть, уже взрослые",  
									},
								]}
							/>
						</FormItem>
						<FormItem style={{ paddingBottom: '5px' }} top="Религия">
							<Select 
								onChange={event => setReligion(event.target.value)}
								placeholder="Не важно"
								value={religion}
								required			
								options={[
									{
										value: '0',
										label: "Не важно", 
									},
									{   
										value: '1',
										label: "Христианство",  
									},
									{   
										value: '2',
										label: "Ислам",  
									},
									{   
										value: '3',
										label: "Иудаизм",  
									},
									{   
										value: '4',
										label: "Буддизм",  
									},
									{   
										value: '5',
										label: "Язычество",  
									},
									{   
										value: '6',
										label: "Атеизм",  
									},
									{   
										value: '7',
										label: "Другая",  
									},
								]}
							/>
						</FormItem>
						
						
						
						
						
						<FormItem style={{ paddingBottom: '5px' }} top="Спиртные напитки">
							<Select 
								onChange={event => setAlcohol(event.target.value)}
								placeholder="Не важно"
								value={alcohol}
								required			
								options={[
									{
										value: '0',
										label: "Не пью", 
									},
									{   
										value: '1',
										label: "Выпиваю",  
									},
								]}
							/>
						</FormItem>
						
						<FormItem style={{ paddingBottom: '5px' }} top="Азартные игры">
							<Select 
								onChange={event => setGames(event.target.value)}
								placeholder="Не важно"
								value={games}
								required			
								options={[
									{
										value: '0',
										label: "Не играю", 
									},
									{   
										value: '1',
										label: "Игроман",  
									},
								]}
							/>
						</FormItem>
						
						
						<FormItem style={{ paddingBottom: '5px' }} top="Курение">
							<Select 
								onChange={event => setSmoking(event.target.value)}
								placeholder="Не важно"
								value={smoking}
								required			
								options={[
									{
										value: '0',
										label: "Не курю", 
									},
									{   
										value: '1',
										label: "Курю",  
									},
								]}
							/>
						</FormItem> 
 
						<FormItem style={{ marginTop: '5px' }} >
							<Button 
								before={<Icon28Write style={{ display: 'inline-block' , verticalAlign: 'sub' }} width={20} height={20} /> }
								onClick={submitFormQuestionnaire}
								size="l" 
								appearance="accent" 
								stretched
							>
								Сохранить
							</Button>
						</FormItem>	


					</form>	
			 
				</Card>		
				
				 
 	
			</CardGrid>		

			 
		</Div>	 	
	</>
  )
}

export default Edit;
 