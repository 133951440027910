import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import axios from '../lib/axios'
import { useAuth } from '../hooks/auth'
import { Spinner, useAdaptivity, viewWidth, Placeholder, Button, Header, HorizontalScroll, HorizontalCell, Avatar } from '@vkontakte/vkui';
import { Icon56Hearts2CircleFillTwilight, Icon16Users } from '@vkontakte/icons';
 
const Login = ({ setPopout, setActivePanel, setErrorText })  => {
	
	/// Login
	const { login } = useAuth({ middleware: 'guest' })
 
	/// Ширина окна
	const { viewWidth } = useAdaptivity();
	/// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
	/// Пользователи топ
	const [fetchedUserBd, setUser] = React.useState([]);
	/// Клик по кнопке вход
	const params = window.location.search.slice(1);
	const BntClickStart = () => {
		login({ params, setActivePanel, setErrorText })
	}
 
	/// UseEffect
	useEffect(() => {
		/// Axios запрос Start
		const getStart = () => {
			axios.get('/api/start')
			.then(response => {
				// Пользователи 
				setUser(response.data);  
				// Выключить Spinner
				setPopout(null);   
			})
		}	
		getStart()  	
	}, []);

    return(
		<>
		  <Placeholder
				icon={<Icon56Hearts2CircleFillTwilight />}
				header={<span>Привет</span>}  
				action={<Button style={{ marginBottom: '30px' , marginTop: '30px' }} onClick={BntClickStart} size="l">Начать знакомства</Button>}
				>
				Мы поможем подобрать тебе пару <br /> и найти новые знакомства
 
				<HorizontalScroll showArrows style={ isDesktop ? {  marginTop: '50px' , paddingBottom: "20px"  } : { marginTop: '50px' , paddingBottom: "20px"  }} >
					{fetchedUserBd.length ? (
						<div style={{ display: "flex" }}>
							{fetchedUserBd.map(user => (  
								<HorizontalCell style={{ display: 'block' }} key={user.id} size="l" header={user.name} onClick={BntClickStart} >
									<Avatar size={69} mode="image" src={user.photo} />
								</HorizontalCell>
							))}
						</div>
						):( 
							<Spinner size="large" style={{ margin: "20px 0" }} />
						)}
					</HorizontalScroll>
					Проявляйте симпатию <br /> 
					Твоя любовь всего в пару кликов
			</Placeholder>
		</>
	); 
}
 
export default Login