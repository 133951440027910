import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import axios from '../lib/axios'
import { useAuth } from '../hooks/auth'
import { CustomSelect, useAdaptivity, DatePicker, Div, PanelHeader, Button, Banner, Text, CardGrid, Card, FormLayout, FormItem, Input, Select, Caption, Link, CustomSelectOption } from '@vkontakte/vkui';
import { Icon24Camera, Icon28CancelCircleFillRed } from '@vkontakte/icons';
 
const Register = ({ setActivePanel , setPopout , setSnackbar , setSnackbarText, setErrorText }) => {
	
	/// Register
	const { register } = useAuth({ middleware: 'guest' })
 
	/// Ширина окна
	const { viewWidth } = useAdaptivity();
	/// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
 
	/// Поля с формы
	const [vk, setVk] = useState(null);
	const [name, setName] = useState(null);
	const [city, setCity] = useState(null);
	const [sex, setSex] = useState('2');
	const [purpose, setPurpose] = useState('1');
	const [day, setDay] = useState('1');
	const [month, setMonth] = useState('1');
	const [year, setYear] = useState('1994');
	const [photo, setPhoto] = useState('https://vk.com/images/camera_400.png');
 
	/// Регистрация клик по форме
	const params = window.location.search.slice(1);
	const submitForm = () => {	 
		register({ params, vk, name, city, sex, purpose, day, month, year, photo, setActivePanel , setSnackbar , setSnackbarText , setErrorText })	 
	}
	
 
  
	/// Город
	const [searchable, setSearchable] = React.useState(true);
    const [remoteQuery, setRemoteQuery] = React.useState("");
    const [fetching, setFetching] = React.useState(false);
    const [remoteUsers, setRemoteUsers] = React.useState([]);
 
	/// Город
	let timeout;
	const cleanFetchingTimeout = () => {
		if (timeout) {
		  clearTimeout(timeout);
		}
	};

	/// Город
	const searchRemoteUsers = (e) => {
    const _remoteQuery = e.target.value;
    cleanFetchingTimeout();
	
	// Записываем начало города
	setRemoteQuery(_remoteQuery);
	
	// Если меньше то очищаем
    if (_remoteQuery.length < 3) {
      setRemoteUsers([]);
      setFetching(false);
    } else {
		// Делаем запрос на города
		fetchRemoteUsers(_remoteQuery);
    }
	};

	/// Город
	const fetchRemoteUsers = (_remoteQuery) => {
    setFetching(true);
    axios.get('/api/citie?query='+_remoteQuery)
			.then(response => {
				setRemoteUsers(response.data);
				setFetching(false);
				cleanFetchingTimeout();
			})
			.catch(error => {
				// Error
				setSnackbar('error');
				setSnackbarText('Поиск города');
				console.log(error); 
			});
	};
 
	/// Город
    const renderDropdown = ({ defaultDropdownContent }) => {
    if (remoteQuery.length < 3) {
      return (
        <Text
          style={{ padding: 12, color: "var(--vkui--color_text_secondary)" }}
        >
          Нужно ввести хотя бы 3 символа
        </Text>
      );
    }
    return defaultDropdownContent;
  };
	
 
	
 
	

	/// UseEffect
	useEffect(() => {
		// Пользователь с Вк
		async function fetchDataBridge() {
			const userInfo = await bridge.send('VKWebAppGetUserInfo');
			if(userInfo){ 
				//console.log(userInfo);
				setVk(userInfo.id);	
				setName(userInfo.first_name);  
				setPhoto(userInfo.photo_max_orig); 
				setSex(''+userInfo.sex+''); 
				if(userInfo.city){ setCity(userInfo.city.title);}
				// // Spinner
				setPopout(null);  
			}
		}
		fetchDataBridge();
		// Город
		return () => cleanFetchingTimeout();
 
	
	}, []);
	
	
	 
 

	return (
	<>
		<PanelHeader separator={false} >Анкета</PanelHeader>
		
		<Div style={ isDesktop ? {   } : { padding:"15px 0px" }}>
			<CardGrid size={isDesktop ? ( "m" ) : ( "l" )} >	
				<Card> 
					<Banner
						style={{ padding: '0' , margin: '0' }}
						mode="image"
						header="Сервис для поиска любви"
						subheader={<span>Мы поможем подобрать пару <br /> и найти новые знакомства </span>}
						size="s"      
						background={
							<div style={{
								backgroundColor: "#65c063",
								backgroundImage: "url(https://sun9-59.userapi.com/7J6qHkTa_P8VKRTO5gkh6MizcCEefz04Y0gDmA/y6dSjdtPU4U.jpg)",
								backgroundPosition: "right bottom",
								backgroundSize: 350,
								backgroundRepeat: "no-repeat",
							}}
							/>
						}
					/>
				</Card>
				<Card>				
					<Banner 
						style={{ padding: '0' , margin: '0' }}
						mode="image"
						header="Заполните информацию"
						subheader={<span>Сервис предназначен для   <br /> лиц достигшим совершеннолетия </span>}
						background={
							<div style={{
								backgroundColor: "#5b9be6",
								backgroundImage: "url(https://sun9-31.userapi.com/PQ4UCzqE_jue9hAINefBMorYCdfGXvcuV5nSjA/eYugcFYzdW8.jpg)",
								backgroundPosition: "right bottom",
								backgroundSize: 230,
								backgroundRepeat: "no-repeat",
							}}
							 />
						}
					/>
				</Card> 
		
				<Card>			  
					<Div>			
						<img style={{ objectFit: "cover" , width: "100%" , height: "379px" , borderRadius: "7px" ,  marginTop: "5px" }} src={photo} />
						
						<Caption style={{ marginTop:'10px' , textAlign: 'center' }} level="3">
							Перед тем как загрузить   <br/>
							или заменить фото создайте анкету 
						</Caption>
					</Div>
  				
				</Card>
 
				<Card>
					<FormLayout>
 
						<FormItem top="Ваше имя">
							<Input 
								type="text" 
								placeholder="Ваше имя" 
								onChange={event => setName(event.target.value)}
								value={name}
								required 
								autoFocus
							/>
						</FormItem>

						<FormItem>
							<CustomSelect
								onChange={event => setCity(event.target.value)}
								options={remoteUsers}
								searchable
								placeholder="Введите город"
								onInputChange={searchRemoteUsers}
								onOpen={
								  searchable ? undefined : remoteUsers.length === 0 && fetchRemoteUsers
								}
								onClose={() => { console.log("CLOSED [async CustomSelect]"); }}
								fetching={fetching}
								renderDropdown={searchable && !fetching && renderDropdown}
								renderOption={({ option, ...restProps }) => (
									<CustomSelectOption 
										{...restProps}
										description={option.description}
									> 
										{option.label}  
									</CustomSelectOption>
								)}
							/>
						</FormItem>

						<FormItem>
							<Select 
								onChange={event => setSex(event.target.value)}
								placeholder="Мужской"
								value={sex}
								required
								options={[
									{
										value: "1",
										label: "Женский", 
									},
									{   
										value: "2",
										label: "Мужской",  
									},
								]}
							/>
						</FormItem>
									
						<FormItem>
							<Select 
								onChange={event => setPurpose(event.target.value)} 
								placeholder="Общения и флирт" 
								value={purpose}
								required
								options={[
									{
										value: "1",
										label: "Общения и флирт",
									},
									{ 
										  
										value: "2",
										label: "Серьезные знакомства",
									},
									{
										 
										value: "3",
										label: "Секс знакомства",
									},
								]}
							/>
						</FormItem>
								  
						<FormItem top="Дата рожденья">
							<DatePicker
								required
								min={{ day: 1, month: 1, year: 1901 }}
								max={{ day: 1, month: 1, year: 2004 }}
								defaultValue={{ day: 1, month: 1, year: 1994 }}
								onDateChange={(value) => {
									setDay(value.day)
									setMonth(value.month)
									setYear(value.year)
								}}
							/>
						</FormItem>
 
						<FormItem>
							<Button 
								onClick={submitForm}
								size="l" 
								appearance="accent" 
								stretched
							>
								Создать анкету
							</Button>
						</FormItem>	
						
						<Caption style={{ marginTop:'4px' , textAlign: 'center' , marginBottom: '12px' }} level="3">
							Нажимая «Создать анкету», вы  принимаете 
							<Link href="#" target="_blank"> пользовательское cоглашение </Link>  
							и 
							<Link href="#" target="_blank"> политику конфиденциальности</Link>.
						</Caption>
								
					</FormLayout>
				</Card>		
			</CardGrid>			
	  	</Div>
	</>
	)
}

export default Register