import React, { useState, useEffect } from 'react';
import { useAdaptivity, viewWidth, Tabbar, TabbarItem, Counter } from '@vkontakte/vkui';
import { Icon28SearchLikeOutline, Icon28LikeOutline, Icon28Users3Outline, Icon28MessageHeartOutline, Icon28UserCircleOutline } from '@vkontakte/icons';
 
const Menu = ({ activePanel, setActivePanel, user }) => {
	
	// Ширина окна
	const { viewWidth } = useAdaptivity();
	// Размер экрана для ПК версии
    const isDesktop = viewWidth > '2'; 
 
	return (
 
		<Tabbar style={isDesktop ? { position:'static' } : { zIndex: "3" }}>
 
			<TabbarItem onClick={()=>setActivePanel('search')} selected={activePanel === "search"} text="Поиск" > 
				<Icon28SearchLikeOutline />
			</TabbarItem>
 
			<TabbarItem onClick={()=>setActivePanel('like')} selected={activePanel === "like"} text="Симпатии" indicator={  user?.like_count ?  ( <Counter size="s" mode="prominent">{user?.like_count}</Counter> ) : (false) }> 
				<Icon28LikeOutline />
			</TabbarItem> 	
 
			<TabbarItem onClick={()=>setActivePanel('visit')} selected={activePanel === "visit"} text="Мои гости" indicator={  user?.visit_count ?  ( <Counter size="s" mode="prominent">{visit_count}</Counter> ) : (false) }> 
				<Icon28Users3Outline />
			</TabbarItem> 	
 
			<TabbarItem onClick={()=>setActivePanel('im')} selected={activePanel === "im"} text="Сообщения" indicator={  user?.dialog_count ?  ( <Counter size="s" mode="prominent">{user?.dialog_count}</Counter> ) : (false) }> 
				<Icon28MessageHeartOutline />
			</TabbarItem> 
 
			<TabbarItem onClick={()=>setActivePanel('profil')} selected={activePanel === "profil"} text="Анкета" > 
				<Icon28UserCircleOutline />
			</TabbarItem> 
 
		</Tabbar>
       
	);
}
 
export default Menu;






